<template>
  <transition name="menu">
    <form-lettertype :is-edit="true"/>
  </transition>
</template>

<script>
import formLettertype from './components/formLettertype'

export default {
  name: 'EditformLettertype',
  components: { formLettertype }
}
</script>

